import React, { useState, useEffect } from "react"
import "../layout.css"
import axios from "axios"
import { Container, Spinner } from "react-bootstrap"
import styled from "styled-components"
import LogoTransfez from "../../images/logo-transfez-new.svg"
import { Link, navigate } from "gatsby"
import { useLocation } from "@reach/router"
import { ForgotPasswordJson } from "../location"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimesCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons"

function ForgotPasswordSg() {
  const { search } = useLocation()
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")
  const ForgotPasswordContent = ForgotPasswordJson()
  const { Title, Button, Point1, Point2, Placeholder } = ForgotPasswordContent
  const newValue = search.replace("?reset_password_token=", "")
  const [isLoading, setIsLoading] = useState(false)
  const [isUnauthorized, setIsUnauthorized] = useState(false)
  const [isWrong, setIsWrong] = useState(false)
  const [inputForm, setInputForm] = useState({
    password: "",
    password_confirmation: "",
    reset_password_token: newValue || "",
  })

  const isPasswordSame = inputForm.password == inputForm.password_confirmation
  const isPasswordSix = inputForm.password.length >= 6
  const isTokenEmpty = search == ""

  function onChange(e) {
    let { name, value } = e.target

    const newInputForm = {
      ...inputForm,
      [name]: value,
    }
    setInputForm(newInputForm)
    setIsUnauthorized(false)
    setIsLoading(false)
    setIsWrong(false)
  }

  const handleSubmit = e => {
    e.preventDefault()
    setIsLoading(true)

    if (isTokenEmpty) {
      setIsUnauthorized(true)
      return
    }

    if (isPasswordSame && isPasswordSix) {
      return axios
        .put("https://api.sg.production.transfez.com/api/auth/password", {
          password: inputForm.password,
          password_confirmation: inputForm.password_confirmation,
          reset_password_token: inputForm.reset_password_token,
        })
        .then(res => {
          navigate(isEnglish ? `/en/reset-password` : `/reset-password`)
          setIsLoading(false)
        })
        .catch(err => {
          console.log(err)
          setIsLoading(false)
        })
    } else {
      setIsWrong(true)
      setIsLoading(false)
    }
  }

  return (
    <>
      <ContainerForgot>
        <div>
          <Link to={isEnglish ? `/en/` : `/`}>
            <LogoImg src={LogoTransfez} alt="logo-transfez" />
          </Link>
          <ForgotPasswordRounded>
            <h3>{Title}</h3>
            <form onSubmit={handleSubmit}>
              <FormForPassword>
                <label>{Point1}</label>
                <input
                  onChange={onChange}
                  name="password"
                  type="password"
                  placeholder={Placeholder}
                  minLength="6"
                  required
                ></input>
              </FormForPassword>
              <FormForPassword>
                <label>{Point2}</label>
                <input
                  onChange={onChange}
                  name="password_confirmation"
                  type="password"
                  placeholder={Placeholder}
                  minLength="6"
                  required
                ></input>
              </FormForPassword>

              {isWrong ? (
                <InlineText>
                  <FontAwesomeIcon
                    icon={faMinusCircle}
                    size="xs"
                    style={{ color: "#dc3545" }}
                  />
                  <small className="text-danger">
                    {" "}
                    The password and confirmation password do not match.{" "}
                  </small>
                </InlineText>
              ) : null}

              {isUnauthorized ? (
                <InlineText>
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    size="xs"
                    style={{ color: "#ffc107" }}
                  />
                  <small className="text-warning"> Unauthorized </small>
                </InlineText>
              ) : null}

              {isLoading ? (
                <InlineText>
                  <Spinner animation="border" size="sm" variant="light" />
                  <small>
                    {" "}
                    Your application is being processed, please waitâ€¦{" "}
                  </small>
                </InlineText>
              ) : null}

              <SavePasswordButton type="submit">{Button}</SavePasswordButton>
            </form>
          </ForgotPasswordRounded>
        </div>
      </ContainerForgot>
    </>
  )
}

export default ForgotPasswordSg

const ContainerForgot = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  > div {
    text-align: center;
    max-width: 450px;
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
`

const LogoImg = styled.img`
  width: 180px;
  height: 53px;
  margin-bottom: 1.5rem;
`

const ForgotPasswordRounded = styled.div`
  border-radius: 20px;
  padding: 1.5rem;
  background-image: linear-gradient(-45deg, #196e97 0%, #48a9b5 100%);
  > h3 {
    font-size: 1.75rem;
    color: white;
    text-align: center;
    font-family: "GothamMedium";
  }
`

const FormForPassword = styled.div`
  position: relative;
  margin-bottom: 1.5rem;
  font-family: "GothamLight";
  > label {
    font-size: 12px;
    position: absolute;
    top: 8px;
    left: 10px;
  }
  > input {
    width: 100%;
    padding-top: 30px !important;
    height: 60px;
    border-radius: 0px;
    border-bottom-left-radius: 10px;
    border: 0;
    font-size: 1rem;
    padding: 0.375rem 0.75rem;
    outline: none;
  }
  > input::placeholder {
    color: #727272;
  }
`

const SavePasswordButton = styled.button`
  font-family: "GothamMedium";
  color: white;
  background-color: #48a9b5;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-left: none;
  border-right: none;
  cursor: pointer;
  padding: 0.5rem;
  margin-top: 3rem;
  width: 100%;
`

const InlineText = styled.div`
  float: left;
  > small {
    color: white;
    font-family: "GothamLight";
  }
`
